import React from "react"
import { ComposableMap, Geographies, Geography,Marker,Annotation } from "react-simple-maps"
import geoURL from "../../utils/provincias.json"
import MapPointerIcon from "../svgComponent/mapPointerIcon"
import styles from "./mapPage.module.scss"
import mapPin from "../../images/map-pin.png"
import buttonPointMap from "../../images/button_point_map.svg"

//pointDirectionLeft contiene las provincias donde al seleccionar el puntero debe apuntar a la direccion contraria
const pointDirectionLeft = ["Córdoba","Mendoza","San Luis","San Juan","La Rioja","Catamarca","Tucumán","Salta","Jujuy","Neuquén"]
export default function MapChart({coords,setSelectedPoint,selectedPoint,setSeePointDetail,isMobile}) {

  const selectedPoints = ["Buenos Aires","Córdoba","Chubut","Santa Fe"]
  const points = {}
  coords.forEach(pdv=>{
    points[pdv.province] ?
    points[pdv.province] = points[pdv.province]+ 1 :
    points[pdv.province] =1
    })
    //coordenadas para setear los estilos en mobile para el marcador segun la provincia
  const pointCoordsStyles = {
    "Buenos Aires":{
      rotate:270,
      width:400
    },
    "Córdoba":{
      rotate:257,
      width:700
    },
    "Santa Fe":{
      rotate:265,
      width:700,
    },
    "Chubut":{
      rotate:210,
      width:300,
    },
    "Santiago del Estero":{
      rotate:260,
      width:900,
    },
    "Mendoza":{
      rotate:240,
      width:600,
    },
    "Jujuy":{
      rotate:240,
      width:1100,
    },
    "Salta":{
      rotate:240,
      width:1000,
    },
    "Tucumán":{
      rotate:258,
      width:950,
    },
    "Tucumán":{
      rotate:258,
      width:950,
    },
    "La Rioja":{
      rotate:251,
      width:850,
    },
    "La Rioja":{
      rotate:245,
      width:800,
    },
    "San Luis":{
      rotate:250,
      width:650,
    },
    "La Pampa":{
      rotate:245,
      width:450,
    },
    "Río Negro":{
      rotate:230,
      width:400
    },
    "Entre Ríos":{
      rotate:270,
      width:650
    },
    "Corrientes":{
      rotate:275,
      width:800
    },
    "Misiones":{
      rotate:281,
      width:900
    },
    "Chaco":{
      rotate:268,
      width:900
    },
    "Formosa":{
      rotate:268,
      width:1000
    },
    "Tierra del Fuego":{
      rotate:null,
      width:null
    },
    "Neuquén":{
      rotate:228,
      width:500
    }
  }
  const selectedProvince = {
    default: {
        fill: "#062441",
        stroke: "#062441",
        strokeWidth: isMobile ? 2 : 1,
        outline: "none",
        transition: "all 250ms",
      },
      hover: {
        fill: "#062441",
        stroke: "#062441",
        strokeWidth: isMobile ? 2 : 1,
        outline: "none",
        transition: "all 250ms",
      },
      pressed: {
        fill: "#062441",
        stroke: "#062441",
        strokeWidth: isMobile ? 2 : 1,
        outline: "none",
        transition: "all 250ms",
      },
  }
  const provinceNOselected= {
    default: {
      fill: "transparent",
      stroke: "#062441",
      strokeWidth: isMobile ? 2 : 1,
      outline: "none",
      transition: "all 250ms",
    },
    hover: {
      fill: "transparent",
      stroke: "#062441",
      strokeWidth: isMobile ? 2 : 1,
      outline: "none",
      transition: "all 250ms",
    },
    pressed: {
      fill: "transparent",
      stroke: "#062441",
      strokeWidth: isMobile ? 2 : 1,
      outline: "none",
      transition: "all 250ms",
    },
  }

  return (
    <>
    <ComposableMap
               projectionConfig={{
                scale: isMobile ? 2500 :1000,
                rotate: [60, 0, -3],
                center: [-5, -37],
              }}
              style={{
               height: "100%",
               width: "100%",
              }}
              disableOptimization
        >
      <Geographies geography={geoURL}>
        {({ geographies }) =>
         {
            return geographies.map((geo) => (
            <Geography
            key={geo.rsmKey}
            geography={geo}
            style={coords.find(data=>data.province === geo.properties.NAME) ? selectedProvince : provinceNOselected}
              />
          ))}
        }
      </Geographies>

      {coords.map(data=>{
        const reverse = pointDirectionLeft.includes(data.province)
        const {width,rotate} = pointCoordsStyles[data.province]
        return <>
        {
          selectedPoint?.id === data.id && <> <Annotation
          subject={isMobile && data.province!== "Tierra del Fuego" ? [-58.08870722728364,-47.12452905444526 ] : [data.lng, data.lat]}
          dx={ isMobile && data.province!== "Tierra del Fuego" ? 0 : reverse ? -150 : 150}
          dy={0}
          connectorProps={{
            stroke: "#062441",
            strokeWidth: 2,
            strokeLinecap: "round"
          }}
     >
      {isMobile && data.province!== "Tierra del Fuego" ?
       <rect width={width} height={5} fill={"#062441"} style={{transform:`rotate(${rotate}deg)`}}/>:
       !isMobile || data.province!== "Tierra del Fuego"  ? <>
       <text className={styles.selected_point_text} dx={reverse ?  -130:10}>{selectedPoint.namePoint}</text>
       <image href={mapPin} width={12} x={reverse ?  -130 : 10 } y={10}/>
       <text className={styles.selected_point_address} dx={ reverse ?-115 :25} dy={20}>
         {selectedPoint.address}
       </text>
       <image href={buttonPointMap} width={75} x={reverse ?  -130 : 10} y={30} onClick={()=>setSeePointDetail(true)}/></> :  null
      }

     </Annotation>
     </>
        }
        <Marker
          coordinates={[
            data.lng - 1.5 ,
            data.lat + 1.8]}
            onClick={ () => setSelectedPoint(data)}
        >

            <MapPointerIcon width={ isMobile ? 100: 40} height={isMobile ? 100: 40} data={data}/>
        </Marker>
        </>
      }
        )}
    </ComposableMap>
    {
    selectedPoint && isMobile ? <div className={styles.selected_point}>
                <h3>{selectedPoint.namePoint}</h3>
                <div>
                    <img src={mapPin} width={15}/>
                    <p>{selectedPoint?.address}</p>
                </div>
                <button onClick={()=>{
                  if(window) {
                    window.dataLayer.push({
                      'event' : 'GAEvent',
                      'event_category': 'Content',
                      'event_action': `${selectedPoint.province}`,
                      'event_label': `${selectedPoint.namePoint}`,
                      'interaction': 'True',
                      'component_name': 'boton_ver_maps',
                      'element_text': `Ver en maps`,
                    });
                  }
                  setSeePointDetail(true)
                  }}> VER EN MAPS</button>
          </div> : null
       }
       {selectedPoint === null || isMobile ?  <p className={styles.points_of_sale}>
        Nuestros puntos: <br/>
        {Object.entries(points)
        .map(data=><strong>{`${data[0]} (${data[1]})`}</strong>)}
    </p>: null}

  </>

  )
}
