import React, { useEffect, useState } from "react"
import styles from "./mapPage.module.scss"
import coronaLogo from "../../images/logo_corona_pdv.svg"
import arrowLeft from "../../images/arrow_left_dark.png"
import Map from "./Map"
import MapDetail from "./MapDetail.js"
import { IsMobileDevice } from "../../utils"
import logo_color from "../../../assets/images/logos/logo_color.png"

export default ({ coords }) => {
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [seePointDetail, setSeePointDetail] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])
  /*
    const coords = [
        {
          id:1,
          lat:-36.90773094385673,
          lng:-58.743349062046256,
          province:"Buenos Aires",
          address:"La Plata, Buenos Aires"
        },{
          id:2,
          lat:-38.62653787394263,
          lng:-60.964446731277256,
          province:"Buenos Aires",
          address:"Necochea, Buenos Aires"
        },
        {
          id:3,
          lat:-31.830276670109644,
          lng:-63.6284579750005,
          province:"Córdoba",
          address:"Río Tercero,Córdoba"
        },
        {
          id:4,
          lat:-43.24894163960116,
          lng: -65.31202105446432,
          province:"Chubut",
          address:"Trelew, Chubut"
        },
        {
          id:5,
          lat:-31.67558179632443,
           lng:-60.76626983918121,
          province:"Santa Fe",
          address:"Santo Tomé,Santa Fe"
        }
      ]
    */
  return (
    <>
      <div className={styles.container}>
        {/*
       <nav className={styles.navigation}>
                   <img src={arrowLeft}/> <span>VOLVER</span>
                </nav>
      */}

        <main>
          <img src={logo_color} className={styles.logo} />
          <h1 className={styles.title}>
            HACÉ CLICK Y CONOCE <br />
            NUESTROS PUNTOS
          </h1>
          <p className={styles.subtitle}>
            Una plataforma donde encontrarás historias únicas creadas por los
            content creators más talentosos del mundo.
          </p>
          {seePointDetail && (
            <button
              className={styles.back_to_map}
              onClick={() => setSeePointDetail(false)}
            >
              VOLVER ATRAS
            </button>
          )}
          <div className={styles.map_container}>
            {seePointDetail ? (
              <MapDetail selectedPoint={selectedPoint} coords={coords} />
            ) : (
              <Map
                coords={coords}
                setSelectedPoint={setSelectedPoint}
                selectedPoint={selectedPoint}
                setSeePointDetail={setSeePointDetail}
                isMobile={isMobile}
              />
            )}
          </div>
        </main>
      </div>
    </>
  )
}
