import React from "react"
import styles from "./activities.module.scss"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper-vars.scss"
import "swiper/swiper.scss"

import calendarYellow from "../../../assets/images/icons/calendarYellow.svg"
import relojYellow from "../../../assets/images/icons/relojYellow.svg"

export const Activities = ({ data }) => {
  
  return (
    <div className={styles.container}>
      <h1 className={styles.tittle}>INVIERNO CON CORONA</h1>
      {data.map((act,i)=>{
          return  <div className={styles.content} style={{flexDirection:i%2==0? "row": "row-reverse"}}>
          <div className={styles.image}>
            <span className={styles.tag}> {act.tag}</span>
            <img src={act.img} />{" "}
          </div>
          <div className={styles.text}>
            <h1> {act.title} </h1>
            <h2 className={styles.subtittle}>{act.description}</h2>

            {act.activityDate && (
              <div style={{ paddingBottom: "2%" }}>
                <img className={styles.calendar} src={calendarYellow} />
                <span className={styles.textCalendar}>
                  {act.activityDate}
                </span>
              </div>
            )}

            {act.activityHour && (
              <div>
                <img className={styles.reloj} src={relojYellow} />
                <span className={styles.textCalendar}>
                  {act.activityHour}
                </span>
              </div>
            )}
            <Link to={act.title=== "TaDá" ? "https://link.tada.com.ar/3qSL/Ecorona" : `/inviernocorona/form?t=${act.title.trim()}`}
            target="blank"
            className={styles.btn} onClick={() => {
                if(window) {
                    window.dataLayer.push({
                        'event' : 'GAEvent',
                        'event_category': 'Content',
                        'event_action': `Boton`,
                        'event_label': `Inscribirme`,
                        'interaction': 'True',
                        'component_name': 'boton_inscribirme',
                        'element_text': `Inscribirme`,
                    });
                }
          }}>
            {act.title=== "TaDá"? "conoce más" :"INSCRIBIRME"}
          </Link>
          </div>
        </div>
      })}
      {/*

      <div className={styles.content}>
        <div className={styles.image}>
          <span className={styles.tag}> {data[0].tag}</span>
          <img src={data[0].img} />{" "}
        </div>
        <div className={styles.text}>
          <h1> {data[0].title} </h1>
          <h2 className={styles.subtittle}>{data[0].description}</h2>

          {data[0].activityDate && (
            <div style={{ paddingBottom: "2%" }}>
              <img className={styles.calendar} src={calendarYellow} />
              <span className={styles.textCalendar}>
                {data[0].activityDate}
              </span>
            </div>
          )}

          {data[0].activityHour && (
            <div>
              <img className={styles.reloj} src={relojYellow} />
              <span className={styles.textCalendar}>
                {data[0].activityHour}
              </span>
            </div>
          )}
        </div>
      </div>

     2da Actividad
      <div className={styles.content}>
        <div className={styles.text}>
          <h1> {data[1].title}</h1>
          <h2 className={styles.subtittle}>{data[1].description}</h2>

          {data[1].activityDate && (
            <div style={{ paddingBottom: "2%" }}>
              <img className={styles.calendar} src={calendarYellow} />
              <span className={styles.textCalendar}>
                {data[1].activityDate}
              </span>
            </div>
          )}

          {data[1].activityHour && (
            <div>
              <img className={styles.reloj} src={relojYellow} />
              <span className={styles.textCalendar}>
                {data[1].activityHour}
              </span>
            </div>
          )}
        </div>
        <div className={styles.image}>
          <span className={styles.tag}> {data[1].tag}</span>
          <img src={data[1].img} />{" "}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.image}>
          <span className={styles.tag}> {data[2].tag} </span>
          <img src={data[2].img} />{" "}
        </div>
        <div className={styles.text}>
          <h1> {data[2].title} </h1>
          <h2 className={styles.subtittle}>{data[2].description}</h2>
          {data[2].activityDate && (
            <div style={{ paddingBottom: "2%" }}>
              <img className={styles.calendar} src={calendarYellow} />
              <span className={styles.textCalendar}>
                {data[2].activityDate}
              </span>
            </div>
          )}
          {data[2].activityHour && (
            <div>
              <img className={styles.reloj} src={relojYellow} />
              <span className={styles.textCalendar}>
                {data[2].activityHour}{" "}
              </span>
            </div>
          )}{" "}
          <Link to="/form" className={styles.btn} onClick={() => {
            window.dataLayer.push({
              'event' : 'GAEvent',
              'event_category': 'Content',
              'event_action': `Boton`,
              'event_label': `Inscribirme`,
              'interaction': 'True',
              'component_name': 'boton_inscribirme',
              'element_text': `Inscribirme`,
            });
          }}>
            INSCRIBIRME
          </Link>
        </div>
      </div>
    */}
    </div>
  )
}

export const ActivitiesMobile = ({ data }) => {
  return (
    <>
      <Swiper
        pagination={true}
        spaceBetween={30}
        centeredSlides={true}
        slidesPerView={1.3}
      >
        {data.map((act,i)=>{
          return  <SwiperSlide>
          <div className={styles.superCard}>
            <div className={styles.cardContainer}>
              <div className={styles.imgCont}>
                <img className={styles.pictM} src={act.img_mobile} />
              </div>
              <div className={styles.cardText}>
                <h1>{act.title}</h1>
                <h2>{act.description}</h2>
                {act.activityDate && (
                  <li>
                    <img className={styles.calendar} src={calendarYellow} />
                    <span> {act.activityDate} </span>
                  </li>
                )}
                {act.activityHour && (
                  <li>
                    <img className={styles.reloj} src={relojYellow} />
                    <span style={{ color: "#2F384A" }}>
                      {" "}
                      {act.activityHour}
                    </span>
                  </li>
                )}
                <Link to={act.title=== "TaDá" ? "https://link.tada.com.ar/3qSL/Ecorona" : `/inviernocorona/form?t=${act.title.trim()}`}
                 className={styles.btnMobile}
                 target="blank"
                onClick={() => {
                    if(window) {
                        window.dataLayer.push({
                            'event' : 'GAEvent',
                            'event_category': 'Content',
                            'event_action': `Boton`,
                            'event_label': `Inscribirme`,
                            'interaction': 'True',
                            'component_name': 'boton_inscribirme',
                            'element_text': `Inscribirme`,
                        });
                    }
                }}>
                   {act.title=== "TaDá"? "conoce más" :"INSCRIBIRME"}
                </Link>
              </div>
            </div>
          </div>

          </SwiperSlide>
        })}
        {/*

        <SwiperSlide>
          <div className={styles.superCard}>
            <div className={styles.cardContainer}>
              <div className={styles.imgCont}>
                <img className={styles.pictM} src={data[0].img_mobile} />
              </div>
              <div className={styles.cardText}>
                <h1>{data[0].title}</h1>
                <h2>{data[0].description}</h2>
                {data[0].activityDate && (
                  <li>
                    <img className={styles.calendar} src={calendarYellow} />
                    <span> {data[0].activityDate} </span>
                  </li>
                )}
                {data[0].activityHour && (
                  <li>
                    <img className={styles.reloj} src={relojYellow} />
                    <span style={{ color: "#2F384A" }}>
                      {" "}
                      {data[0].activityHour}
                    </span>
                  </li>
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.superCard}>
            <div className={styles.cardContainer}>
              <div className={styles.imgCont}>
                <img className={styles.pictM} src={data[1].img_mobile} />
              </div>
              <div className={styles.cardText}>
                <h1>{data[1].title}</h1>
                <h2>{data[1].description}</h2>
                {data[1].activityDate && (
                  <li>
                    <img className={styles.calendar} src={calendarYellow} />
                    <span> {data[1].activityDate} </span>
                  </li>
                )}
                {data[1].activityHour && (
                  <li>
                    <img className={styles.reloj} src={relojYellow} />
                    <span style={{ color: "#2F384A" }}>
                      {" "}
                      {data[1].activityHour}
                    </span>
                  </li>
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.superCard}>
            <div className={styles.cardContainer}>
              <div className={styles.imgCont}>
                <img className={styles.pictM} src={data[2].img_mobile} />
              </div>
              <div className={styles.cardText}>
                <h1> {data[2].title}</h1>
                <h2>{data[2].description}</h2>
                {data[2].activityDate && (
                  <li>
                    <img className={styles.calendar} src={calendarYellow} />
                    <span> {data[2].activityDate} </span>
                  </li>
                )}
                {data[2].activityHour && (
                  <li>
                    <img className={styles.reloj} src={relojYellow} />
                    <span style={{ color: "#2F384A" }}>
                      {" "}
                      {data[2].activityHour}
                    </span>
                  </li>
                )}
                <Link to="/form" className={styles.btnMobile} onClick={() => {
                  window.dataLayer.push({
                    'event' : 'GAEvent',
                    'event_category': 'Content',
                    'event_action': `Boton`,
                    'event_label': `Inscribirme`,
                    'interaction': 'True',
                    'component_name': 'boton_inscribirme',
                    'element_text': `Inscribirme`,
                  });
                }}>
                  INSCRIBIRME
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        */}
      </Swiper>
    </>
  )
}
