import React from 'react'
import styles from '../styles/footerCasaCorona.module.scss';
import facebook from '../../assets/images/icons/facebookCC.svg'
import instagram from '../../assets/images/icons/instagramcC.svg'
import Div100vh from 'react-div-100vh';
const FooterCasaCorona = ({isMobile})=>{
    return (
        <footer className={styles.footeraside}>
            <div className={styles.socialMenu}>
                <a href="https://www.facebook.com/cervezacorona" target="_blank">
                    <img className={'imgSocialFace'} src={facebook} />
                </a>
                <a href="https://www.instagram.com/corona_argentina" target="_blank">
                    <img className={'imgSocialInsta'} src={instagram} />
                </a>
            </div>
            <div className="column justify-content-center">
                <div className="col">
                    {isMobile ? (
                          <p className={styles.legalterms}>
                          No se quede conectado si comparte el<br /> dispositivo con  menores. <br />
                          BEBER CON MODERACIÓN. PROHIBIDA SU<br /> VENTA A MENORES DE 18 AÑOS. NO <br />COMPARTA EL CONTENIDO CON MENORES.<br /> ®️2021 Corona - Buenos Aires - Argentina.<br /> Todos los derechos reservados.
                      </p>
                    ):(
                          <p className={styles.legalterms}>
                          No se quede conectado si comparte el dispositivo con  menores. <br />
                          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO COMPARTA EL CONTENIDO CON MENORES. ®️2021 Corona - Buenos Aires - Argentina. Todos los derechos reservados.
                      </p>
                    )}
                  
                </div>
                <div className="col">
                    {/* <div className="row text-center pb-4 justify-content-center"> */}
                        {isMobile ? (
                            <>
                            <div className={styles.termsFoot} >
                                <a className={styles.termLink} href={"/TyCCervezaCorona.pdf"} target="_blank" download><p>Términos de uso  </p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={"/ppdp.pdf"} target="_blank" download><p>Políticas de privacidad</p></a>
                            </div>
                            <div className={styles.termsFoot}>
                                <a className={styles.termLink} href={"https://www.instagram.com/corona_argentina"} target="_blank" download><p>Contacto</p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={'https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/'} target="_blank" download><p>consumo responsable</p></a>
                            </div>
                                <div className={styles.termsFoot}>
                                    <a className={styles.termLink} href={'https://www.cerveceriaymalteriaquilmes.com/'} target="_blank" download><p>INFORMACIÓN OFICIAL DE LA COMPAÑÍA</p></a>
                                </div>
                            </>
                        ):(
                            <div className="row text-center pb-4 justify-content-center">
                                <a className={styles.termLink} href={"/TyCCervezaCorona.pdf"} target="_blank" download><p>Términos de uso  </p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={"/ppdp.pdf"} target="_blank" download><p>Políticas de privacidad</p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={"https://www.instagram.com/corona_argentina"} target="_blank" download><p>Contacto</p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={'https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/'} target="_blank" download><p>consumo responsable</p></a>
                                <span >|</span>
                                <a className={styles.termLink} href={'https://www.cerveceriaymalteriaquilmes.com/'} target="_blank" download><p>INFORMACIÓN OFICIAL DE LA COMPAÑÍA</p></a>
                            </div>
                        )}
                       
                    {/* </div> */}

                </div>
            </div>
        </footer>
    )
}

export default FooterCasaCorona