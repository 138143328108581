import customStyleMap from "./custom.json"
import React, { useState } from "react";
import GoogleMapReact from 'google-map-react';
import MapPointerIcon from "../svgComponent/mapPointerIcon";
import styles from "./mapPage.module.scss"
import warnIcon from "../../images/warn_icon.svg"

export default function SimpleMap({selectedPoint,coords}){
    const [infoData,setInfoData] = useState(false)
    const [blockScreen,setBlockScreen] = useState(true)

  return (
    <div style={{ height: '100vh', width: '100%',position:"relative" }}>
      {blockScreen && <div className={styles.blur_block}>
        <div>
          <img src={warnIcon}/>
        </div>
        <p>ARRASTRA EL MAPA Y DESCUBRI TODOS LOS PUNTOS</p>
        <button onClick={()=>{
            if(window) {
                window.dataLayer.push({
                    'event' : 'GAEvent',
                    'event_category': 'Content',
                    'event_action': `Card`,
                    'event_label': `Continuar`,
                    'interaction': 'True',
                    'component_name': 'boton_continuar',
                    'element_text': `Continuar`,
                });
            }
          setBlockScreen(false)}
          }>continuar</button>
      </div>}

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k" }}
        defaultCenter={selectedPoint}
        defaultZoom={7}
        options={{ styles: customStyleMap }}
      >

        { !blockScreen && coords.map(data=><MapPointerIcon
          key={data.address}
          lat={data.lat}
          lng={data.lng}
          zoom={7}
          width={50}
          height={50}
          showData={infoData.id === data.id}
          setShowData={()=>setInfoData(data)}
          data={data}
          closeInfo={()=>setInfoData({})}
        />)}

      </GoogleMapReact>
    </div>
  );
}
