import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/swiper.scss"
import "swiper/swiper-vars.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"

import "../../styles/page_casaCorona.css"
import styles from "../../styles/page_casaCorona.module.scss"

// Components
import {
  SliderSession,
  SliderSessionMobile,
} from "../../components/Slider_sessions"
import {
  CasaCoronaBanner,
  CasaCoronaBannerMobile,
} from "../../components/Slider_banner"
import SEO from "../../components/seo"
import AgeGate2 from "../../components/agegate"
import {
  Activities,
  ActivitiesMobile,
} from "../../components/Activities/Activities"
import FooterCasaCorona from "../../components/FooterCasaCorona"

import { IsMobileDevice, CleanArrayImages } from "../../utils/index"
import bottSlide1 from "../../images/bottSlide1.png"
import bottSlide2 from "../../images/bottSlide2.png"
import panco from "../../images/panco.jpg"
import MapPage from "../../components/MapPage"
import AgeGateBoolean from "../../components/AgeGate/AgegateBoolean"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const Winter = ({ data, location }) => {
  const sessions = data.allSessions.edges
  const coronaBanners = data.allCasaCorona.edges
  const activities = data.allActivities.edges.map(ac=>ac.node)
  const coords = data.allPdv.edges.map(pdv=>pdv.node)
  coords.forEach(pdv=>{
    pdv.lat= Number(pdv.lat)
    pdv.lng = Number(pdv.lng)
  })
  const myRef2 = useRef(null)
  const myRef4 = useRef(null)
  const [isMobile, setIsMobile] = useState(false)
  const [Images] = useState(CleanArrayImages(data))
  const [slide, setSlide] = useState(0)
  const [form, setForm] = useState(false)
  const [name, setName] = useState(false)
  const [email, setEmail] = useState(false)
  const [telephone, setTelephone] = useState(false)
  const [dni, setDni] = useState(false)
  const [msg, setMsg] = useState(false)
  const [provincia, setProvincia] = useState(false)
  const [gusto, setGusto] = useState(false)
  const [load, setLoad] = useState(false)
  const [age, setAge] = useState(true)


  let shows = [
    // {
    //     primera:laFemme,
    //     segunda:bottSlide1,
    //     tercera:festaBros,
    //     one: 'Le Femme D´Argent',
    //     two: 'Lisandro Skar',
    //     three: 'Festa Bros',
    //     day: "SÁBADO 9 DE ENERO, 18HS",
    //     eventId: 1
    // },
    // {
    //     primera:palta,
    //     segunda:bottSlide4,
    //     tercera:bottSlide3,
    //     one: 'Palta & the moods',
    //     two: 'TBD',
    //     three: 'Dr. Root',
    //     day: "SÁBADO 23 DE ENERO, 18HS",
    //     eventId: 2
    // },
    {
      primera: bottSlide1,
      segunda: bottSlide2,
      tercera: panco,
      one: "CORONA SUNSET",
      two: "TBD",
      three: "Doctor Roberts",
      day: "SÁBADO 30 DE ENERO, 18HS",
      eventId: 3,
    },
  ]
  useEffect(() => {
    let local = localStorage.getItem('age') || sessionStorage.getItem('age')
    if (local) setAge(false)
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    if(window){
      window.dataLayer.push({
        brand: "Corona",
        Zone: "SAZ",
        country: "ARG",
        city: "CABA",
        campaign: `${
            window.location.search.includes("?cmp=")
                ? window.location.search.split("?cmp=")[1].split("&")[0]
                : ""
        }`,
        cms: "0",
        event: "pageView",
        language: "es",
        login: false,
        pageName: "Casa Corona",
        pageType: "Content page",
        SiteType: "Brand",
        product: "NA",
        sku: "NA",
        userUid: "",
        url: "https://www.cervezacorona.com.ar/casacorona",
        url_campaign: `${
            window.location.search.includes("utm_campaign=")
                ? window.location.search.split("utm_campaign=")[1]
                : ""
        }`,
        Step_number: "" ,
      })
    }

  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoad(true)
    }, 900)
  }, [])

  const handleNext = () => {
    if (slide == 1) setSlide(0)
    else {
      setSlide(slide + 1)
    }
  }
  const handlePrevious = () => {
    if (slide == 0) setSlide(1)
    else {
      setSlide(slide - 1)
    }
  }

  const handleForm = () => {
    if (!form) {
      setForm(true)
      document.getElementById("imgsCont").style.left = "-20vw"
      document.getElementById("form").style.opacity = 1
    } else {
      setForm(false)
      document.getElementById("imgsCont").style.left = 0
      document.getElementById("form").style.opacity = 0
    }
  }
  const handleFormM = () => {
    if (!form) {
      setForm(true)
      document.getElementById("formM").style.left = "2vw"
      document.getElementById("formM").style.opacity = 1
    } else {
      setForm(false)
      document.getElementById("formM").style.left = "-100vw"
      document.getElementById("formM").style.opacity = 0
    }
  }
  const sendInscription = obj => {
    //http://localhost:8080/api/casacoronainscripciones
    // https://wacoronabe-prd.azurewebsites.net/api/casacoronainscripciones
    fetch(
      "https://wacoronabe-prd.azurewebsites.net/api/casacoronainscripciones",
      {
        method: "POST",
        body: JSON.stringify(obj),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  }
  const handleSubmit = e => {
    e.preventDefault()
    let obj = {
      fullname: name,
      dni,
      email,
      telephone,
      province: provincia,
      gusto,
      day: shows[slide].day,
      eventId: shows[slide].eventId,
      activity: "CASA CORONA",
    }
    sendInscription(obj)
    setMsg(true)
    setTimeout(() => {
      setForm(false)
      setMsg(false)
      handleForm()
    }, 2000)
  }
  const handleSubmitM = e => {
    e.preventDefault()
    let obj = {
      fullname: name,
      dni,
      email,
      telephone,
      province: provincia,
      gusto,
      day: shows[slide].day,
      eventId: shows[slide].eventId,
      activity: "CASA CORONA",
    }
    sendInscription(obj)
    setMsg(true)
    setTimeout(() => {
      setForm(false)
      setMsg(false)
      handleFormM()
    }, 2000)
  }

  const actividades = () => myRef2.current.scrollIntoView()
  const actividadesM = () => myRef4.current.scrollIntoView()

  return <>
      {!load ? null : (
        <div style={{height: "100vh", overflowX: "hidden"}}>
          <SEO title="Casa Corona" />
          {isMobile ? (
            <>
            {/*
              <Navbar
                typeLeftLogo="3"
                typeRightLogo="2"
                typeArrowLogo_mobile="2"
                typeRightLogoMobile="2"
                typeCenterLogo_mobile="2"
                background="white"
                light={props.light}
                isMobile={isMobile}
                casaCorona={true}
              />
            */}
              <CasaCoronaBannerMobile
                actividadesM={actividadesM}
                data={coronaBanners}
              />
              <div className={styles.swiper} ref={myRef4}>
                <h1>NATURAL ICE BAR</h1>
                <SliderSessionMobile data={sessions} />
              </div>
              <div className={styles.swiper}>
                <h1>INVIERNO CON CORONA</h1>
                <ActivitiesMobile data={activities}  />
              </div>
              <MapPage coords={coords}/>
            </>
          ) : (
            <div >
              <div className={styles.deskContainer}>
                {/*
                <Navbar
                  typeLeftLogo="3"
                  typeRightLogo="2"
                  typeArrowLogo_mobile="2"
                  typeRightLogoMobile="2"
                  typeCenterLogo_mobile="2"
                  background="white"
                  light={props.light}
                  isMobile={isMobile}
                  casaCorona={true}
                />
                */}
                <CasaCoronaBanner
                  actividades={actividades}
                  data={coronaBanners}
                />
              </div>
              <div className={styles.swiperDesktop} ref={myRef2}>
                <h1>
                NATURAL ICE BAR
                </h1>
                {/* <div className={styles.actividadesContainer}> */}
                <SliderSession data={sessions} />
              </div>
              <Activities data={activities} />
              <MapPage coords={coords}/>
            </div>
          )}
          {/* {isMobile ? (
            <>
              <div className={styles.swiper2}>
                <h1>PROTOCOLO COVID</h1>
                <h2>
                  Para cuidarnos es obligatorio el uso de tapabocas. Tenemos
                  puntos de desinfección en barras, baños y livings. Debemos
                  mantener distanciamiento en puntos de expendio, baños y al
                  circular fuera de las burbujas. Hay burbujas con sombrillas y
                  livings de 4 personas, y carpas de 6 personas. Medimos la
                  temperatura en el ingreso y los cupos de gente son limitados.
                </h2>
                <Swiper
                  spaceBetween={1}
                  slidesPerView={1.7}
                  pagination={{ clickable: true }}
                  onSwiper={swiper => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <div className={styles.superCard}>
                      <div className={styles.cardContainer}>
                        <div className={styles.imgCont}>
                          <img className={styles.pictM} src={tapaBoca} />
                        </div>
                        <div className={styles.cardText}>
                          <h1>
                            TAPABOCAS
                            <br />
                            OBLIGATORIO
                          </h1>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={styles.superCard}>
                      <div className={styles.cardContainer}>
                        <div className={styles.imgCont}>
                          <img className={styles.pictM} src={jabon} />
                        </div>
                        <div className={styles.cardText}>
                          <h1>
                            PUNTOS
                            <br />
                            DE DESINFECCIÓN
                          </h1>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={styles.superCard}>
                      <div className={styles.cardContainer}>
                        <div className={styles.imgCont}>
                          <img className={styles.pictM} src={distancia} />
                        </div>
                        <div className={styles.cardText}>
                          <h1>
                            DISTANCIAMIENTO
                            <br />
                            SOCIAL
                          </h1>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={styles.superCard}>
                      <div className={styles.cardContainer}>
                        <div className={styles.imgCont}>
                          <img className={styles.pictM} src={carpa} />
                        </div>
                        <div className={styles.cardText}>
                          <h1>
                            BURBUJAS PARA
                            <br />4 Y 6 PERSONAS
                          </h1>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className={styles.superCard}>
                      <div className={styles.cardContainer}>
                        <div className={styles.imgCont}>
                          <img className={styles.pictM} src={pistola} />
                        </div>
                        <div className={styles.cardText}>
                          <h1>
                            CONTROL
                            <br />
                            DE TEMPERATURA
                          </h1>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide></SwiperSlide>
                </Swiper>
              </div>
            </>
          ) : (
            <>

              <div className={styles.protocolo}>
                <div className={styles.textContainer}>
                  <h1>
                    PROTOCOLO
                    <br />
                    COVID
                  </h1>
                  <h2>
                    Para cuidarnos es obligatorio el uso de tapabocas. Tenemos
                    puntos de desinfección en barras, baños y livings. Debemos
                    mantener distanciamiento en puntos de expendio, baños y al
                    circular fuera de las burbujas. Hay burbujas con sombrillas
                    y livings de 4 personas, y carpas de 6 personas. Medimos la
                    temperatura en el ingreso y los cupos de gente son
                    limitados.
                  </h2>
                </div>
                <div className={styles.iconsContainer}>
                  <div className={styles.tapaboca}>
                    <img src={tapaBoca} />
                    <h2>
                      TAPABOCAS
                      <br />
                      OBLIGATORIO
                    </h2>
                  </div>
                  <div className={styles.tapaboca}>
                    <img src={jabon} />
                    <h2>
                      PUNTOS
                      <br />
                      DE DESINFECCIÓN
                    </h2>
                  </div>
                  <div className={styles.tapaboca}>
                    <img src={distancia} />
                    <h2>
                      DISTANCIAMIENTO
                      <br />
                      SOCIAL
                    </h2>
                  </div>
                  <div className={styles.tapaboca}>
                    <img src={carpa} />
                    <h2>
                      BURBUJAS PARA
                      <br />
                      OBLIG4 Y 6 PERSONAS
                    </h2>
                  </div>
                  <div className={styles.tapaboca}>
                    <img src={pistola} />
                    <h2>
                      CONTROL
                      <br />
                      DE TEMPERATURA
                    </h2>
                  </div>
                </div>
              </div>
            </>
          )} */}

          <div className="row">
            <FooterCasaCorona isMobile={isMobile} />
          </div>
        </div>
      )}
    </>
 
}


export const backgrounds = graphql`
  query winter {
    allVideos(filter: { site: { eq: "casacorona" } }) {
      nodes {
        site
        video
        video_mobile
      }
    }
    allFile(filter: { relativeDirectory: { eq: "page_casaCorona" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allSessions(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          img
          img_mobile
          index
          secretCode
          title
          subtitle
          sessionDate
          cta
          link
        }
      }
    }
    allCasaCorona(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          img
          img_mobile
          index
          text
          title
          subtitle
          link
          btnText
        }
      }
    }
    allActivities(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          img
          img_mobile
          index
          title
          description
          activityDate
          tag
        }
      }
    }
    allPdv {
      edges{
        node {
          id
          address
          province
          lat
          lng
          namePoint
        }
      }
    }
  }
`

export default Winter;
