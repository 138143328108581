import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Autoplay } from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper-vars.scss"
import "swiper/swiper.scss"

import "../styles/page_casaCorona.css"
import styles from "../styles/page_casaCorona.module.scss"
// import logoDesk from "../images/sunCC.png"
import arrDesk from "../../assets/images/icons/arrDesk.svg"
import porronDesk from "../images/porronPlayaDesk.jpg"
import gps from "../../assets/images/icons/gps.svg"
import arrow from "../../assets/images/icons/arrowCC.svg"

SwiperCore.use([Pagination, Autoplay])

export const CasaCoronaBanner = ({ data, actividades }) => {
  return (
    <>
      {data.map((e, index) => {
        return (
            <>
              <div className={styles.headContainer}>
                <div className={styles.left}>
                  {/* <img src={logoDesk} /> */}
                  <h1>{e.node.title}</h1>
                  <p>{e.node.subtitle}</p>

                  {e.node.text ? (
                    <>
                      <h4>Encontranos en:</h4>
                      <div className={styles.gpsCC}>
                        <img src={gps} />
                        <h3>{e.node.text}</h3>
                      </div>
                    </>
                  ) : null}

                  {e.node.link ? (
                    <a href={`${e.node.link}`} onClick={() => {
                      if(window) {
                        window.dataLayer.push({
                          'event' : 'GAEvent',
                          'event_category': 'Carousel',
                          'event_action': `${index}:${e.node.title}`,
                          'event_label': `${e.node.btnText}`,
                          'interaction': 'True',
                          'component_name': 'boton_ver_mas_carousel',
                          'element_text': `${e.node.btnText}`,
                        });
                      }
                    }}>
                      <button type="button">{e.node.btnText}</button>
                    </a>
                  ) : null}
                  <div className={styles.arrow}>
                    <img
                      src={arrDesk}
                      alt="casaCoronaButtonScroll"
                      onClick={actividades}
                    ></img>
                  </div>
                </div>
                <div className={styles.right}>
                  <img src={e.node.img} />
                </div>
              </div>
            </>
        )
      })}
    </>
  )
}

export const CasaCoronaBannerMobile = ({ data, actividadesM }) => {
  return (
    <>
      {data.map((e, index) => {
        return (
            <div className={styles.logoContainerCC}>
              <img className={styles.backgroundImg} src={e.node.img} />
              <h1>{e.node.title}</h1>
              <p>{e.node.subtitle}</p>
              {e.node.text ? (
                <>
                  <h4>Encontranos en:</h4>
                  <div className={styles.gpsCC}>
                    <img src={gps} />
                    <h3>{e.node.text}</h3>
                  </div>
                </>
              ) : null}

              {e.node.link ? (
                <a href={`${e.node.link}`}>
                  <button type="button" onClick={() => {
                    if(window) {
                      window.dataLayer.push({
                        'event' : 'GAEvent',
                        'event_category': 'Carousel',
                        'event_action': `${index}:${e.node.title}`,
                        'event_label': `${e.node.btnText}`,
                        'interaction': 'True',
                        'component_name': 'boton_ver_mas_carousel',
                        'element_text': `${e.node.btnText}`,
                      });
                    }
                  }}>{e.node.btnText}</button>
                </a>
              ) : null}
              <div className={styles.arrow} onClick={actividadesM}>
                <img src={arrow} alt="casaCoronaButtonScroll"></img>
              </div>
            </div>
        )
      })}
    </>
  )
}
